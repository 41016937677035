<template>
    <div>
        <div class="main-title">限时活动</div>
        <div class="content-list-page page-content">
            <div class="list-head-box">
                <div data-v-69874ad0="" class="list-head">
                    <p data-v-69874ad0="" class="list-head-title">温馨提示</p>
                    <ul data-v-69874ad0="">
                        <li data-v-69874ad0="">************</li>
                    </ul>
                </div>
            </div>
            <div class="margin-b-sm margin-l-sm">
                <span class="fl-l" aria-label="状态">
                    <el-select v-model="searchData.status" clearable placeholder="状态" size="mini">
                        <el-option :value="2" label="关闭"></el-option>
                        <el-option :value="1" label="开启"></el-option>
                    </el-select>
                </span>
                <span class="fl-l margin-l-sm" aria-label="活动名称">
                    <el-input v-model="searchData.name" clearable placeholder="活动名称" size="mini"></el-input>
                </span>
                <span class="fl-l margin-l-sm" aria-label="状态">
                    <el-date-picker v-model="searchData.datePick" type="daterange" range-separator="至" size="mini"
                        start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                </span>
                <span class="margin-l-sm fl-l">
                    <el-button type="primary" icon="el-icon-search" size="small" @click="search">搜索</el-button>
                </span>

                <span class=" fl-r">
                    <router-link to="/aimActivity/add" class="router-link">
                        <el-button type="primary" size="small">新增</el-button>
                    </router-link>
                </span>
            </div>

            <div class="table-content margin-t-sm">
                <el-table :header-cell-style="{ padding: 0 }" ref="singleTable" :data="tableData" v-loading="loading"
                    style="width: 100%;background: #fff;">
                    <el-table-column label="ID" property="id" width="80" align="center">
                    </el-table-column>


                    <el-table-column label="活动名字" property="name" align="center">
                    </el-table-column>
                    <el-table-column label="创建人" property="creator" align="center">
                    </el-table-column>

                    <el-table-column label="图片" property="pic" width="120" align="center">
                        <template slot-scope="scope">
                            <el-image style="width: 40px; height: 40px" :src="path + scope.row.cover_img" fit="contain"
                                lazy :preview-src-list="[path + scope.row.cover_img]">
                            </el-image>
                        </template>
                    </el-table-column>

                    <el-table-column property="created_at" label="创建时间" align="center">
                    </el-table-column>

                    <el-table-column property="start_at" label="开始时间" align="center">
                    </el-table-column>
                    <el-table-column property="end_at" label="结束时间" align="center">
                    </el-table-column>
                    <el-table-column label="状态" property="status" align="center">
                        <template slot-scope="scope">
                            <div>
                                <el-switch v-model="scope.row.status" :active-value="1" :inactive-value="2"
                                    @change="updataStatus(scope.row.id, scope.row.status)" active-color="#13ce66"
                                    inactive-color="#ff4949">
                                </el-switch>
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column width="300" label="操作">
                        <template slot-scope="scope">
                            <span class="margin-r-sm">
                                <el-link slot="reference" type="primary" @click="editAimActivity(scope.row.id)">编辑
                                </el-link>
                            </span>
                            <span>
                                <el-popconfirm icon="el-icon-info" icon-color="red" title="删除后将不可恢复，确定删除吗？"
                                    @confirm="deleted(scope.row.id)">
                                    <el-link slot="reference" type="primary">删除</el-link>
                                </el-popconfirm>
                            </span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div class="table-batch margin-l-sm">
                <span class="fl-l">
                    <el-pagination small background :current-page="page" :page-sizes="[10, 20]" :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper" @size-change="setPageSize"
                        @current-change="setPage" :total="total">
                    </el-pagination>
                </span>
                <div style="clear: both"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import { config } from '../../../js/config'
export default {
    name: "aimActivity",
    data() {
        return {
            //图片前缀
            path: config.imageUrl,
            //表单加载动画
            loading: false,
            //表单数据
            tableData: [],
            //搜索的数据
            searchData: {},
            //分页
            page: 1,
            pageSize: 10,
            total: 0,
        }
    },
    created() {
        this.getList();
    },
    methods: {
        ...mapActions('aimActivity', ['getAimActivityList', 'delAimActivity']),
        ...mapActions('common', ['setStatus']),

        //搜索
        search() {
            if (this.searchData.datePick == undefined) this.searchData.datePick = []
            this.getList();
        },
        //分页
        setPageSize(pageSize) {
            this.pageSize = pageSize
            this.getList()
        },
        setPage(page) {
            this.page = page
            this.getList()
        },
        //状态改变
        async updataStatus(id, status) {
            let from = {
                id,
                "model": "AimActivity\\AimActivityModel",
                status,
            }
            const { res_info } = await this.setStatus(from)
            if (res_info != 'ok') return
            this.$message.success('修改成功');
            await this.getList()
        },
        //编辑
        editAimActivity(row) {
            this.$router.push('/aimActivity/detail/' + row)
        },
        //删除
        async deleted(row) {
            const { res_info } = await this.delAimActivity(row)
            if (res_info != 'ok') return
            this.$message.success('删除成功');
            await this.getList()
        },
        //获取列表数据
        async getList() {
            this.loading = true
            // console.log(this.searchData);
            // this.searchData.datePick = []||this.searchData.datePick
            let from = {
                ...this.searchData,
                page: this.page,
                pageSize: this.pageSize,
            }
            const { res_info, data } = await this.getAimActivityList(from)
            if (res_info != 'ok') return
            this.total = data.total
            this.tableData = data.list
            this.loading = false
        }
    }
}
</script>
